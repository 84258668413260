"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _system = require("@superficial/system");

var _react = require("react");

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var Overlay = function Overlay(_ref) {
  var onClick = _ref.onClick;
  return (0, _system.jsx)("div", {
    onClick: onClick,
    sx: {
      zIndex: 2,
      position: 'fixed',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    }
  });
};

var _default = (0, _react.forwardRef)(function (_ref2, ref) {
  var children = _ref2.children,
      open = _ref2.open,
      _ref2$side = _ref2.side,
      side = _ref2$side === void 0 ? 'left' : _ref2$side,
      props = _objectWithoutProperties(_ref2, ["children", "open", "side"]);

  var isLeft = side === 'left';
  var isRight = side === 'right';
  return (0, _system.jsx)(_react.Fragment, null, open && (0, _system.jsx)(Overlay, props), (0, _system.jsx)("div", _extends({}, props, {
    ref: ref,
    sx: {
      boxSizing: 'border-box',
      zIndex: 2,
      position: 'fixed',
      top: 0,
      right: isRight ? 0 : 'auto',
      left: isLeft ? 0 : 'auto',
      bottom: 0,
      minWidth: 250,
      width: 250,
      maxHeight: '100vh',
      mt: 0,
      bg: 'bg.main',
      border: 0,
      borderWidth: 'small',
      borderRightStyle: ['none', isLeft ? 'solid' : 'none'],
      borderLeftStyle: ['none', isRight ? 'solid' : 'none'],
      borderColor: 'border.secondary',
      overflowX: 'visible',
      overflowY: 'auto',
      WebkitOverflowScrolling: 'touch',
      boxShadow: [4, 'none'],
      transform: [open ? 'translateX(0)' : isLeft ? 'translate(-100%)' : 'translate(100%)', 'none'],
      transition: 'transform .2s ease-out'
    }
  }), children));
});

exports["default"] = _default;