"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _system = require("@superficial/system");

var _Box = _interopRequireDefault(require("./../Box"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
/** @jsx jsx */


var _default = (0, _system.styled)(_Box["default"])({
  display: 'flex'
});

exports["default"] = _default;