"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.Footer = exports.Container = exports.Main = exports.Header = exports.Root = void 0;

var _system = require("@superficial/system");

var _Box = _interopRequireDefault(require("./../Box"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(source, true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(source).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var Root = function Root(props) {
  return (0, _system.jsx)(_Box["default"], _objectSpread({}, props, {
    sx: {
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      variant: 'layouts.Page'
    }
  }));
};

exports.Root = Root;

var Header = function Header(_ref) {
  var raised = _ref.raised,
      sticky = _ref.sticky,
      props = _objectWithoutProperties(_ref, ["raised", "sticky"]);

  return (0, _system.jsx)(_Box["default"], _objectSpread({
    as: 'header'
  }, props, {
    sx: _objectSpread({
      zIndex: 1,
      boxSizing: 'border-box',
      position: 'relative',
      display: 'flex'
    }, raised && {
      boxShadow: 2
    }, {}, sticky && {
      position: 'sticky',
      top: 0
    }, {
      variant: 'layouts.Header'
    })
  }));
};

exports.Header = Header;

var Main = function Main(props) {
  return (0, _system.jsx)(_Box["default"], _objectSpread({
    as: 'main'
  }, props, {
    sx: {
      flex: '1 1 auto',
      variant: 'layouts.Main'
    }
  }));
};

exports.Main = Main;

var Container = function Container(props) {
  return (0, _system.jsx)(_Box["default"], _objectSpread({}, props, {
    sx: {
      width: '100%',
      minWidth: 0,
      maxWidth: 1024,
      mx: 'auto',
      variant: 'layouts.Container'
    }
  }));
};

exports.Container = Container;

var Footer = function Footer(props) {
  return (0, _system.jsx)(_Box["default"], _objectSpread({
    as: 'footer'
  }, props, {
    sx: {
      display: 'flex',
      variant: 'layouts.Footer'
    }
  }));
};

exports.Footer = Footer;
var _default = {
  Root: Root,
  Header: Header,
  Main: Main,
  Container: Container,
  Footer: Footer
};
exports["default"] = _default;