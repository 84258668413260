"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.easings = exports.durations = void 0;

var _utils = require("@superficial/utils");

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var durations = {
  fastest: '75ms',
  fast: '150ms',
  normal: '300ms',
  slow: '450ms',
  slowest: '600ms'
};
exports.durations = durations;
var easings = {
  inOut: 'cubic-bezier(0.4, 0.0, 0.2, 1)',
  out: 'cubic-bezier(0.0, 0.0, 0.2, 1)',
  "in": 'cubic-bezier(0.4, 0.0, 1, 1)',
  entering: 'cubic-bezier(0.0, 0.0, 0.2, 1)',
  leaving: 'cubic-bezier(0.4, 0.0, 1, 1)'
};
exports.easings = easings;
var _default = {
  durations: durations,
  easings: easings,
  create: function create() {
    var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : ['all'];
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    var _options$duration = options.duration,
        duration = _options$duration === void 0 ? durations.normal : _options$duration,
        _options$easing = options.easing,
        easing = _options$easing === void 0 ? easings.inOut : _options$easing,
        _options$delay = options.delay,
        delay = _options$delay === void 0 ? 0 : _options$delay,
        other = _objectWithoutProperties(options, ["duration", "easing", "delay"]);

    return (0, _utils.toArray)(props).map(function (animatedProp) {
      return "".concat(animatedProp, " ").concat((0, _utils.isString)(duration) ? duration : (0, _utils.toMs)(duration), " ").concat(easing, " ").concat((0, _utils.isString)(delay) ? delay : (0, _utils.toMs)(delay));
    }).join(',');
  }
};
exports["default"] = _default;