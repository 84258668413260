"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = makePalette;

var _tinycolor = _interopRequireWildcard(require("@ctrl/tinycolor"));

var _bezierEasing = _interopRequireDefault(require("bezier-easing"));

var _colorCurves = require("./colorCurves");

var _colors = require("./colors");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};

  if (obj != null) {
    var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

    for (var key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

        if (desc && (desc.get || desc.set)) {
          Object.defineProperty(newObj, key, desc);
        } else {
          newObj[key] = obj[key];
        }
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) {
    return;
  }

  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

var abs = Math.abs,
    ceil = Math.ceil,
    round = Math.round,
    sqrt = Math.sqrt,
    cos = Math.cos,
    log10 = Math.log10;
var altColorAdjs = {
  A100: {
    hAdj: 2,
    sAdj: 200,
    vAdj: 105
  },
  A200: {
    hAdj: 2,
    sAdj: 182,
    vAdj: 108
  },
  A400: {
    hAdj: 2,
    sAdj: 137,
    vAdj: 113
  },
  A700: {
    hAdj: 4,
    sAdj: 120,
    vAdj: 123
  }
};

var atMost100 = function atMost100(num) {
  return num > 100 ? 100 : num;
};

function generateValStart(v) {
  // given a value between 0 and 100 it returns a value from 50 - 100
  return round(100 - (100 - v / 100 - sqrt(v) + v * cos(2.65)) / (2 * log10(v + 1) + 1.25));
}

function calcShortestDistance(a, b) {
  var difference = abs(a - b) % 360;
  return difference > 180 ? 360 - difference : difference;
}

function calcHueDistance(start, end) {
  start = round(start);
  end = round(end);

  if (start === 0) {
    start = 360;
  }

  if (end === 0) {
    end = 360;
  }

  var hueDistance = calcShortestDistance(start, end);

  if ((start + hueDistance) % 360 === end) {
    return [start, start + hueDistance];
  } else if ((start - hueDistance) % 360 === end) {
    return [start, start - hueDistance];
  } else if ((end + hueDistance) % 360 === start) {
    return [end + hueDistance, end];
  } else {
    return [end - hueDistance, end];
  }
}

function distribute(value, rangeA, rangeB) {
  var _rangeA = _slicedToArray(rangeA, 2),
      fromLow = _rangeA[0],
      fromHigh = _rangeA[1];

  var _rangeB = _slicedToArray(rangeB, 2),
      toLow = _rangeB[0],
      toHigh = _rangeB[1];

  var result = toLow + (value - fromLow) / (fromHigh - fromLow) * (toHigh - toLow);

  if (toLow < toHigh) {
    if (result < toLow) {
      return toLow;
    }

    if (result > toHigh) {
      return toHigh;
    }
  } else {
    if (result > toLow) {
      return toLow;
    }

    if (result < toHigh) {
      return toHigh;
    }
  }

  return result;
} // alt = 'A100' | 'A200' | 'A400' | 'A700'


function getAltColor(color, alt) {
  var _tinycolor$toHsv = (0, _tinycolor["default"])(color).toHsv(),
      h = _tinycolor$toHsv.h,
      s = _tinycolor$toHsv.s,
      v = _tinycolor$toHsv.v;

  var _altColorAdjs$alt = altColorAdjs[alt],
      hAdj = _altColorAdjs$alt.hAdj,
      sAdj = _altColorAdjs$alt.sAdj,
      vAdj = _altColorAdjs$alt.vAdj;
  return (0, _tinycolor["default"])({
    h: (h + hAdj) % 360,
    s: atMost100(s * sAdj),
    v: atMost100(v * vAdj)
  });
}

function generatePointsOnCurve(curve, steps) {
  var array = [];

  for (var step = 0; step < steps; step++) {
    array.push(curve(step / (steps - 1)));
  }

  return array;
}

function generateShades(_ref) {
  var hueStart = _ref.hueStart,
      hueEnd = _ref.hueEnd,
      _ref$hueCurve = _ref.hueCurve,
      hueCurve = _ref$hueCurve === void 0 ? _colorCurves.easeInQuad : _ref$hueCurve,
      satStart = _ref.satStart,
      satEnd = _ref.satEnd,
      _ref$satCurve = _ref.satCurve,
      satCurve = _ref$satCurve === void 0 ? _colorCurves.easeOutQuad : _ref$satCurve,
      _ref$satRate = _ref.satRate,
      satRate = _ref$satRate === void 0 ? 100 : _ref$satRate,
      valStart = _ref.valStart,
      valEnd = _ref.valEnd,
      _ref$valCurve = _ref.valCurve,
      valCurve = _ref$valCurve === void 0 ? _colorCurves.easeOutQuad : _ref$valCurve,
      _ref$steps = _ref.steps,
      steps = _ref$steps === void 0 ? 10 : _ref$steps,
      _ref$format = _ref.format,
      format = _ref$format === void 0 ? null : _ref$format;
  var hueArray = generatePointsOnCurve(hueCurve, steps);
  var satArray = generatePointsOnCurve(satCurve, steps);
  var valArray = generatePointsOnCurve(valCurve, steps).reverse();
  var shades = [];

  for (var index = 0; index < steps; index++) {
    var hueStep = distribute(hueArray[index], [0, 1], calcHueDistance(hueStart, hueEnd));
    var satStep = distribute(satArray[index], [0, 1], [satStart, satEnd]) * (satRate * 0.01);
    var valStep = distribute(valArray[index], [0, 1], [valEnd, valStart]);
    var color = (0, _tinycolor["default"])({
      h: (ceil(hueStep) + 360) % 360,
      s: atMost100(ceil(satStep)),
      v: atMost100(ceil(valStep))
    });
    shades.push((0, _colors.formatColor)(color, format));
  }

  return shades;
}

function generateMaterialUIPalette(_ref2) {
  var hueStart = _ref2.hueStart,
      hueEnd = _ref2.hueEnd,
      _ref2$hueCurve = _ref2.hueCurve,
      hueCurve = _ref2$hueCurve === void 0 ? _colorCurves.muiHueCurve : _ref2$hueCurve,
      satStart = _ref2.satStart,
      satEnd = _ref2.satEnd,
      _ref2$satCurve = _ref2.satCurve,
      satCurve = _ref2$satCurve === void 0 ? _colorCurves.muiSatCurve : _ref2$satCurve,
      _ref2$satRate = _ref2.satRate,
      satRate = _ref2$satRate === void 0 ? 100 : _ref2$satRate,
      valStart = _ref2.valStart,
      valEnd = _ref2.valEnd,
      _ref2$valCurve = _ref2.valCurve,
      valCurve = _ref2$valCurve === void 0 ? _colorCurves.muiValCurve : _ref2$valCurve,
      _ref2$format = _ref2.format,
      format = _ref2$format === void 0 ? 'hex' : _ref2$format,
      getContrastText = _ref2.getContrastText;
  var shades = generateShades({
    hueStart: hueStart,
    hueEnd: hueEnd,
    hueCurve: hueCurve,
    satStart: satStart,
    satEnd: satEnd,
    satCurve: satCurve,
    satRate: satRate,
    valStart: valStart,
    valEnd: valEnd,
    valCurve: valCurve,
    steps: 10,
    format: format
  });
  return {
    50: shades[0],
    100: shades[1],
    200: shades[2],
    300: shades[3],
    400: shades[4],
    500: shades[5],
    600: shades[6],
    700: shades[7],
    800: shades[8],
    900: shades[9],
    A100: (0, _colors.formatColor)(getAltColor(shades[1], 'A100'), format),
    A200: (0, _colors.formatColor)(getAltColor(shades[2], 'A200'), format),
    A400: (0, _colors.formatColor)(getAltColor(shades[4], 'A400'), format),
    A700: (0, _colors.formatColor)(getAltColor(shades[7], 'A700'), format)
  };
}

function makePalette(color) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'hex';
  var defaultColor = (0, _tinycolor["default"])(color);

  var _defaultColor$toHsv = defaultColor.toHsv(),
      h = _defaultColor$toHsv.h,
      s = _defaultColor$toHsv.s,
      v = _defaultColor$toHsv.v;

  var hueStart = h;
  var satStart = round(s * 10);
  var valStart = generateValStart(v * 100);
  var hueEnd = (h + 354) % 360;
  var satEnd = round(atMost100(s * 108));
  var valEnd = round(v * 66);
  var palette = generateMaterialUIPalette({
    hueStart: hueStart,
    satStart: satStart,
    valStart: valStart,
    hueEnd: hueEnd,
    valEnd: valEnd,
    satEnd: satEnd,
    format: format
  }); // palette.colors[500] = formatColor(defaultColor, format);
  // //palette.contrast[500] = formatColor(getContrastText(defaultColor), format);
  // palette.colors.light = palette.colors[300];
  // palette.contrast.light = palette.contrast[300];
  // palette.colors.main = palette.colors[500];
  // palette.contrast.main = palette.contrast[500];
  // palette.colors.dark = palette.colors[700];
  // palette.contrast.dark = palette.contrast[700];

  return palette;
}