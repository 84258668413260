"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _system = require("@superficial/system");

var _propTypes = _interopRequireDefault(require("prop-types"));

var _Box = _interopRequireDefault(require("./../Box"));

var _Button = require("./../Button/Button");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(source, true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(source).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var Icon = function Icon(_ref) {
  var children = _ref.children,
      color = _ref.color,
      htmlColor = _ref.htmlColor,
      titleAccess = _ref.titleAccess,
      size = _ref.size,
      viewBox = _ref.viewBox,
      props = _objectWithoutProperties(_ref, ["children", "color", "htmlColor", "titleAccess", "size", "viewBox"]);

  return (0, _system.jsx)(_Box["default"], _extends({
    color: htmlColor,
    focusable: "false",
    role: titleAccess ? 'img' : 'presentation',
    viewBox: viewBox,
    "aria-hidden": titleAccess ? 'false' : 'true'
  }, props, {
    sx: _objectSpread({
      width: '1em',
      height: '1em',
      display: 'inline-block',
      flexShrink: 0,
      fill: 'currentColor',
      userSelect: 'none'
    }, (0, _Button.isBrandColor)(color) && {
      color: "".concat(color, ".main")
    }, {}, color === 'inherit' && {
      color: 'inherit'
    }, {}, color === 'action' && {
      color: 'text.action'
    }, {}, color === 'disabled' && {
      color: 'text.disabled'
    }, {}, size === 'small' && {
      fontSize: 18
    }, {}, size === 'medium' && {
      fontSize: 20
    }, {}, size === 'large' && {
      fontSize: 24
    }, {
      /** Theme overrides */
      variant: ['icons.base', "icons.".concat(color), "icons.".concat(size)]
    })
  }), children, titleAccess ? (0, _system.jsx)("title", null, titleAccess) : null);
};

Icon.propTypes = {
  children: _propTypes["default"].node.isRequired,
  color: _propTypes["default"].oneOf(['inherit', 'primary', 'secondary', 'tertiary']),
  size: _propTypes["default"].oneOf(['small', 'medium', 'large']),
  viewBox: _propTypes["default"].string
};
Icon.defaultProps = {
  as: 'svg',
  color: 'inherit',
  viewBox: '0 0 24 24'
};
var _default = Icon;
exports["default"] = _default;