"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.muiValCurve = exports.muiSatCurve = exports.muiHueCurve = exports.easeInOutBack = exports.easeOutBack = exports.easeInBack = exports.easeInOutExpo = exports.easeOutExpo = exports.easeInExpo = exports.easeInOutQuint = exports.easeOutQuint = exports.easeInQuint = exports.easeInOutCirc = exports.easeOutCirc = exports.easeInCirc = exports.easeInOutQuart = exports.easeOutQuart = exports.easeInQuart = exports.easeInOutQuad = exports.easeOutQuad = exports.easeInQuad = exports.easeInOutSine = exports.easeOutSine = exports.easeInSine = exports.easeInOutCubic = exports.easeOutCubic = exports.easeInCubic = exports.linear = void 0;

var _bezierEasing = _interopRequireDefault(require("bezier-easing"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var linear = (0, _bezierEasing["default"])(0.5, 0.5, 0.5, 0.5);
exports.linear = linear;
var easeInCubic = (0, _bezierEasing["default"])(0.55, 0.055, 0.675, 0.19);
exports.easeInCubic = easeInCubic;
var easeOutCubic = (0, _bezierEasing["default"])(0.215, 0.61, 0.355, 1);
exports.easeOutCubic = easeOutCubic;
var easeInOutCubic = (0, _bezierEasing["default"])(0.645, 0.045, 0.355, 1);
exports.easeInOutCubic = easeInOutCubic;
var easeInSine = (0, _bezierEasing["default"])(0.47, 0, 0.745, 0.715);
exports.easeInSine = easeInSine;
var easeOutSine = (0, _bezierEasing["default"])(0.39, 0.575, 0.565, 1);
exports.easeOutSine = easeOutSine;
var easeInOutSine = (0, _bezierEasing["default"])(0.445, 0.05, 0.55, 0.95);
exports.easeInOutSine = easeInOutSine;
var easeInQuad = (0, _bezierEasing["default"])(0.55, 0.085, 0.68, 0.53);
exports.easeInQuad = easeInQuad;
var easeOutQuad = (0, _bezierEasing["default"])(0.25, 0.46, 0.45, 0.94);
exports.easeOutQuad = easeOutQuad;
var easeInOutQuad = (0, _bezierEasing["default"])(0.455, 0.03, 0.515, 0.955);
exports.easeInOutQuad = easeInOutQuad;
var easeInQuart = (0, _bezierEasing["default"])(0.895, 0.03, 0.685, 0.22);
exports.easeInQuart = easeInQuart;
var easeOutQuart = (0, _bezierEasing["default"])(0.165, 0.84, 0.44, 1);
exports.easeOutQuart = easeOutQuart;
var easeInOutQuart = (0, _bezierEasing["default"])(0.77, 0, 0.175, 1);
exports.easeInOutQuart = easeInOutQuart;
var easeInCirc = (0, _bezierEasing["default"])(0.6, 0.04, 0.98, 0.335);
exports.easeInCirc = easeInCirc;
var easeOutCirc = (0, _bezierEasing["default"])(0.075, 0.82, 0.165, 1);
exports.easeOutCirc = easeOutCirc;
var easeInOutCirc = (0, _bezierEasing["default"])(0.785, 0.135, 0.15, 0.86);
exports.easeInOutCirc = easeInOutCirc;
var easeInQuint = (0, _bezierEasing["default"])(0.755, 0.05, 0.855, 0.06);
exports.easeInQuint = easeInQuint;
var easeOutQuint = (0, _bezierEasing["default"])(0.23, 1, 0.32, 1);
exports.easeOutQuint = easeOutQuint;
var easeInOutQuint = (0, _bezierEasing["default"])(0.86, 0, 0.07, 1);
exports.easeInOutQuint = easeInOutQuint;
var easeInExpo = (0, _bezierEasing["default"])(0.95, 0.05, 0.795, 0.035);
exports.easeInExpo = easeInExpo;
var easeOutExpo = (0, _bezierEasing["default"])(0.19, 1, 0.22, 1);
exports.easeOutExpo = easeOutExpo;
var easeInOutExpo = (0, _bezierEasing["default"])(1, 0, 0, 1);
exports.easeInOutExpo = easeInOutExpo;
var easeInBack = (0, _bezierEasing["default"])(0.6, -0.28, 0.735, 0.045);
exports.easeInBack = easeInBack;
var easeOutBack = (0, _bezierEasing["default"])(0.175, 0.885, 0.32, 1.275);
exports.easeOutBack = easeOutBack;
var easeInOutBack = (0, _bezierEasing["default"])(0.68, -0.55, 0.265, 1.55);
exports.easeInOutBack = easeInOutBack;
var muiHueCurve = (0, _bezierEasing["default"])(0.34, 0.1, 0.68, 1);
exports.muiHueCurve = muiHueCurve;
var muiSatCurve = (0, _bezierEasing["default"])(0.48, 0.78, 0.31, 0.99);
exports.muiSatCurve = muiSatCurve;
var muiValCurve = (0, _bezierEasing["default"])(0.4, 0.55, 0.66, 1);
exports.muiValCurve = muiValCurve;