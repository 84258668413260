"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.Caption = exports.P = exports.H6 = exports.H5 = exports.H4 = exports.H3 = exports.H2 = exports.H1 = void 0;

var _system = require("@superficial/system");

var _Box = _interopRequireDefault(require("./../Box"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(source, true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(source).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var Text = function Text(_ref) {
  var paragraph = _ref.paragraph,
      props = _objectWithoutProperties(_ref, ["paragraph"]);

  return (0, _system.jsx)(_Box["default"], _objectSpread({}, props, {
    sx: {
      m: 0,
      mb: paragraph ? 3 : '0.35em'
    }
  }));
};

Text.defaultProps = {
  paragraph: false
};

var H1 = function H1(props) {
  return (0, _system.jsx)(Text, _objectSpread({
    as: 'h1'
  }, props, {
    sx: {
      variant: 'styles.h1'
    }
  }));
};

exports.H1 = H1;

var H2 = function H2(props) {
  return (0, _system.jsx)(Text, _objectSpread({
    as: 'h2'
  }, props, {
    sx: {
      variant: 'styles.h2'
    }
  }));
};

exports.H2 = H2;

var H3 = function H3(props) {
  return (0, _system.jsx)(Text, _objectSpread({
    as: 'h3'
  }, props, {
    sx: {
      variant: 'styles.h3'
    }
  }));
};

exports.H3 = H3;

var H4 = function H4(props) {
  return (0, _system.jsx)(Text, _objectSpread({
    as: 'h4'
  }, props, {
    sx: {
      variant: 'styles.h4'
    }
  }));
};

exports.H4 = H4;

var H5 = function H5(props) {
  return (0, _system.jsx)(Text, _objectSpread({
    as: 'h5'
  }, props, {
    sx: {
      variant: 'styles.h5'
    }
  }));
};

exports.H5 = H5;

var H6 = function H6(props) {
  return (0, _system.jsx)(Text, _objectSpread({
    as: 'h6'
  }, props, {
    sx: {
      variant: 'styles.h6'
    }
  }));
};

exports.H6 = H6;

var P = function P(props) {
  return (0, _system.jsx)(Text, _objectSpread({
    as: 'p'
  }, props, {
    sx: {
      color: 'text.secondary',
      variant: 'styles.p'
    }
  }));
};

exports.P = P;

var Caption = function Caption(props) {
  return (0, _system.jsx)(Text, _objectSpread({
    as: 'span'
  }, props, {
    sx: {
      variant: 'styles.caption'
    }
  }));
};

exports.Caption = Caption;
Text.H1 = H1;
Text.H2 = H2;
Text.H3 = H3;
Text.H4 = H4;
Text.H5 = H5;
Text.H6 = H6;
Text.P = P;
Text.Caption = Caption;
var _default = Text;
exports["default"] = _default;