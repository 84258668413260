"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "useEventCallback", {
  enumerable: true,
  get: function get() {
    return _useEventCallback.useEventCallback;
  }
});

var _useEventCallback = require("./useEventCallback");