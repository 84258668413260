/** @jsx jsx */
import {
	Container,
	Flex,
	Header,
	OutlinedButton,
} from '@superficial/components';
import { jsx, useColorMode, useTheme } from '@superficial/system';
import MenuButton from './MenuButton';

const ColorModeButton = () => {
	const theme = useTheme();
	const [ mode, setColorMode ] = useColorMode();

	const handleClick = () => {
		const modes = Object.keys(theme.colors.modes);
		const currentIndex = modes.indexOf(mode);
		const nextMode =
			currentIndex + 1 < modes.length
				? modes[currentIndex + 1]
				: modes[0];
		setColorMode(nextMode);
	};

	return <OutlinedButton onClick={handleClick}>{mode}</OutlinedButton>;
};

export default ({ menuOpen, setMenuOpen, nav }) => (
	<Header
		raised
		sticky
		sx={{
			h: 64,
			bg: 'primary.main',
			ml: [ 0, 250 ],
		}}
	>
		<Container sx={{ p: 2 }}>
			<Flex sx={{ justifyContent: 'space-between' }}>
				<Flex sx={{ alignItems: 'center' }}>
					<MenuButton onClick={e => setMenuOpen(!menuOpen)} />
				</Flex>
				<Flex sx={{ alignItems: 'center' }}>
					<ColorModeButton />
				</Flex>
			</Flex>
		</Container>
	</Header>
);
