export { default as merge } from 'lodash.merge';
export { default as set } from 'dset';

var isString = function isString(n) {
  return typeof n === 'string';
};

var capitalize = function capitalize(str) {
  return isString(str) ? str.charAt(0).toUpperCase() + str.slice(1) : str;
};

var compose = function compose() {
  var arguments$1 = arguments;

  for (var _len = arguments.length, funcs = new Array(_len), _key = 0; _key < _len; _key++) {
    funcs[_key] = arguments$1[_key];
  }

  if (funcs.length === 0) {
    return function (arg) {
      return arg;
    };
  }

  if (funcs.length === 1) {
    return funcs[0];
  }

  return funcs.reduce(function (a, b) {
    return function () {
      return a(b.apply(void 0, arguments));
    };
  });
};

var is = function is(n) {
  return n !== undefined && n !== null;
};

var isNil = function isNil(n) {
  return !is(n);
};

var assign = Object.assign,
    entries = Object.entries,
    keys = Object.keys,
    values = Object.values;

var defaults = function defaults(_defaults) {
  var props = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  if (_defaults === null) {
    return {};
  }

  keys(_defaults).forEach(function (key) {
    if (isNil(props[key])) {
      props[key] = _defaults[key];
    }
  });
  return props;
}; // based on https://github.com/developit/dlv


var get = function get(obj, key, def, p, undef) {
  key = key && key.split ? key.split('.') : [key];

  for (p = 0; p < key.length; p++) {
    obj = obj ? obj[key[p]] : undef;
  }

  return obj === undef ? def : obj;
};

var toString = Object.prototype.toString;

var getTag = function getTag(n) {
  if (n == null) {
    return n === undefined ? '[object Undefined]' : '[object Null]';
  }

  return toString.call(n);
};

function _typeof(obj) {
  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread2(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(source, true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(source).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

var isObjectLike = function isObjectLike(n) {
  return _typeof(n) === 'object' && n !== null;
};

var isArgs = function isArgs(n) {
  return isObjectLike(n) && getTag(n) === '[object Arguments]';
};

var isArray = function isArray(n) {
  return Array.isArray(n);
};
/** Used as references for various `Number` constants. */


var MAX_SAFE_INTEGER = 9007199254740991;

var isLen = function isLen(n) {
  return typeof n === 'number' && n > -1 && n % 1 == 0 && n <= MAX_SAFE_INTEGER;
};

var isArrayLike = function isArrayLike(n) {
  return n !== null && typeof n !== 'function' && isLen(n.length);
};

var isPrototype = function isPrototype(n) {
  return n && n.constructor && (typeof n.constructor === 'function' && n.constructor.prototype === Object.prototype || n === Object.prototype);
};

var hasOwnProperty = Object.prototype.hasOwnProperty;

var removeSpaces = function removeSpaces(str) {
  return str.replace(/\s+/g, '');
};

var isEmpty = function isEmpty(n) {
  if (isNil(n)) {
    return true;
  }

  if (isString(n)) {
    n = removeSpaces(n);
  }

  if (isArrayLike(n) && (Array.isArray(n) || typeof n === 'string' || typeof n.splice === 'function')) {
    return !n.length;
  }

  var tag = getTag(n);

  if (tag === '[object Map]' || tag === '[object Set]') {
    return !n.size;
  }

  for (var key in n) {
    if (hasOwnProperty.call(n, key)) {
      return false;
    }
  }

  return true;
};

var isFn = function isFn(n) {
  return typeof n === 'function';
};

var isMap = function isMap(n) {
  return isObjectLike(n) && getTag(n) === '[object Map]';
};

var isNumber = function isNumber(n) {
  return typeof n === 'number';
};

var isNegative = function isNegative(n) {
  return isNumber(n) && n < 0;
};

var isObject = function isObject(n) {
  if (!isObjectLike(n) || getTag(n) !== '[object Object]') {
    return false;
  }

  if (Object.getPrototypeOf(n) === null) {
    return true;
  }

  var proto = n;

  while (Object.getPrototypeOf(proto) !== null) {
    proto = Object.getPrototypeOf(proto);
  }

  return Object.getPrototypeOf(n) === proto;
};

var isSet = function isSet(n) {
  return isObjectLike(n) && getTag(n) === '[object Set]';
};

var omit = function omit() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var remove = arguments.length > 1 ? arguments[1] : undefined;
  return isNil(remove) || !isArray(remove) || isEmpty(remove) ? obj : keys(obj).reduce(function (acc, key) {
    return !remove.includes(key) ? _objectSpread2({}, acc, _defineProperty({}, key, obj[key])) : acc;
  }, {});
};

var toArray = function toArray(n) {
  return isArray(n) ? n : [n];
};

var pick = function pick() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var keep = arguments.length > 1 ? arguments[1] : undefined;
  var keepUndefined = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  return (isArray(keep) || isString(keep)) && !isEmpty(keep) ? toArray(keep).reduce(function (acc, path) {
    var val = get(obj, path, undefined);
    var key = path.split('.').shift();
    return val || keepUndefined ? _objectSpread2({}, acc, _defineProperty({}, key, val)) : acc;
  }, {}) : obj;
};

var toMs = function toMs(val) {
  return isNumber(Number(val)) ? "".concat(Math.round(val), "ms") : val;
};

var toPx = function toPx(n) {
  return isNumber(n) ? n + 'px' : n;
};

var toRem = function toRem(n) {
  return isNumber(n) ? n + 'rem' : n;
};

export { capitalize, compose, defaults, get, is, isArgs, isArray as isArr, isArray, isArrayLike, isEmpty, isFn, isFn as isFunc, isFn as isFunction, isLen, isMap, isNegative as isNeg, isNegative, isNil, isNumber as isNum, isNumber, isObject as isObj, isObject, isObjectLike, isPrototype, isSet, isString as isStr, isString, assign, entries, keys, values, omit, pick, toArray as toArr, toArray, toMs as toMilliseconds, toMs, toPx as toPixels, toPx, toRem };