/** @jsx jsx */
import {
	Box,
	Button,
	ContainedButton,
	H3,
	H5,
	OutlinedButton,
	IconButton,
	P,
} from '@superficial/components';
import AlarmIcon from './svgs/AlarmIcon';
import DeleteIcon from './svgs/DeleteIcon';
import PhotoIcon from './svgs/PhotoIcon';
import { jsx } from '@superficial/system';
import DemoCard from './DemoCard';

export default () => (
	<Box>
		<H3 paragraph>Buttons</H3>
		<H5 paragraph>
			Buttons allow users to take actions, and make choices, with a single
			tap.
		</H5>
		<P paragraph>
			Additional informaion about Buttons and how great they are and why
			you've never clicked on a button as the ones being rendered before
			you right now... call Google they'll tell you all about it.
		</P>
		<br />

		<H5 paragraph>Contained Buttons</H5>
		<P paragraph>
			Contained buttons are high-emphasis, distinguished by their use of
			elevation and fill. They contain actions that are primary to your
			app. The last example of this demo show how to use an upload button.
		</P>
		<DemoCard>
			<ContainedButton sx={{ m: 2 }}>Default</ContainedButton>
			<ContainedButton color='primary' sx={{ m: 2 }}>
				Primary
			</ContainedButton>
			<ContainedButton color='secondary' sx={{ m: 2 }}>
				Secondary
			</ContainedButton>
			<ContainedButton disabled sx={{ m: 2 }}>
				Disabled
			</ContainedButton>
		</DemoCard>

		<H5 paragraph>Text Buttons</H5>
		<P paragraph>
			Text buttons are typically used for less-pronounced actions,
			including those located:
		</P>
		<ul>
			<li>In dialogs</li>
			<li>In cards</li>
		</ul>
		<P paragraph>
			In cards, text buttons help maintain an emphasis on card content.
		</P>
		<DemoCard>
			<Button sx={{ m: 2 }}>Default</Button>
			<Button color='primary' sx={{ m: 2 }}>
				Primary
			</Button>
			<Button color='secondary' sx={{ m: 2 }}>
				Secondary
			</Button>
			<Button disabled sx={{ m: 2 }}>
				Disabled
			</Button>
		</DemoCard>

		<H5 paragraph>Outlined Buttons</H5>
		<P paragraph>
			Outlined buttons are medium-emphasis buttons. They contain actions
			that are important, but aren’t the primary action in an app.
		</P>
		<DemoCard>
			<OutlinedButton sx={{ m: 2 }}>Default</OutlinedButton>
			<OutlinedButton color='primary' sx={{ m: 2 }}>
				Primary
			</OutlinedButton>
			<OutlinedButton color='secondary' sx={{ m: 2 }}>
				Secondary
			</OutlinedButton>
			<OutlinedButton disabled sx={{ m: 2 }}>
				Disabled
			</OutlinedButton>
		</DemoCard>

		<H5 paragraph>Icon Buttons</H5>
		<P paragraph>
			Icon buttons are commonly found in app bars and toolbars.
		</P>
		<P paragraph>
			Icons are also appropriate for toggle buttons that allow a single
			choice to be selected or deselected, such as adding or removing a
			star to an item.
		</P>
		<DemoCard>
			<IconButton sx={{ m: 2 }}>
				<DeleteIcon />
			</IconButton>
			<IconButton sx={{ m: 2 }} disabled>
				<DeleteIcon />
			</IconButton>
			<IconButton sx={{ m: 2 }} color='secondary'>
				<AlarmIcon />
			</IconButton>
			<IconButton sx={{ m: 2 }} color='primary'>
				<PhotoIcon />
			</IconButton>
		</DemoCard>
	</Box>
);
