"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.styles = exports.space = exports.shadows = exports.lineHeights = exports.fontWeights = exports.fontSizes = exports.fonts = exports.borderWidths = exports.bps = void 0;

var _makeColors = _interopRequireDefault(require("./makeColors"));

var _transitions = _interopRequireDefault(require("./transitions"));

var _utils = require("@superficial/utils");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(source, true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(source).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) {
    return;
  }

  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var bps = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920
};
exports.bps = bps;
var borderWidths = {
  none: '0px',
  small: '1px',
  medium: '2px',
  large: '4px',
  xLarge: '8px'
};
exports.borderWidths = borderWidths;
var fonts = {
  body: 'Roboto, system-ui, sans-serif',
  heading: 'Roboto, system-ui, sans-serif',
  "interface": 'Roboto, system-ui, sans-serif'
};
exports.fonts = fonts;
var fontSizes = [12, 14, 16, 20, 24, 34, 48, 60, 96];
exports.fontSizes = fontSizes;
var fontWeights = {
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700
};
exports.fontWeights = fontWeights;
var lineHeights = [1.75, 1.66, 1.6, 1.5, 1.33, 1.17, 1.04, 1];
exports.lineHeights = lineHeights;
var shadows = ['0 0 2px 0 rgba(0,0,0,.15),0 1px 4px 0 rgba(0,0,0,.2)', '0 0 2px 0 rgba(0,0,0,.15),0 2px 8px 0 rgba(0,0,0,.2)', '0 0 2px 0 rgba(0,0,0,.15),0 3px 12px 0 rgba(0,0,0,.2)', '0 0 2px 0 rgba(0,0,0,.15),0 4px 16px 0 rgba(0,0,0,.2)', '0 0 2px 0 rgba(0,0,0,.15),0 6px 20px 0 rgba(0,0,0,.2)', '0 0 2px 0 rgba(0,0,0,.15),0 8px 32px 0 rgba(0,0,0,.2)', '0 0 2px 0 rgba(0,0,0,.15),0 16px 64px 0 rgba(0,0,0,.2)'];
exports.shadows = shadows;
var space = [0, 4, 8, 16, 32, 64, 128, 256, 512];
exports.space = space;
var styles = {
  global: {
    html: {
      fontSize: '14px'
    },
    body: {
      margin: 0,
      bg: 'bg.main',
      color: 'text.primary',
      fontSize: '14px',
      lineHeight: 3,
      fontWeight: 'regular',
      fontFamily: 'body'
    }
  },
  a: {
    color: 'primary.main',
    textDecoration: 'none',
    ':hover': {
      textDecoration: 'underline'
    }
  },
  hr: {
    bg: 'muted',
    border: 0,
    height: '1px',
    m: 3
  },
  h1: {
    fontSize: 8,
    lineHeight: 7,
    fontWeight: 'light',
    fontFamily: 'heading'
  },
  h2: {
    fontSize: 7,
    lineHeight: 7,
    fontWeight: 'light',
    fontFamily: 'heading'
  },
  h3: {
    fontSize: 6,
    lineHeight: 6,
    fontWeight: 'regular',
    fontFamily: 'heading'
  },
  h4: {
    fontSize: 5,
    lineHeight: 5,
    fontWeight: 'regular',
    fontFamily: 'heading'
  },
  h5: {
    fontSize: 4,
    lineHeight: 4,
    fontWeight: 'regular',
    fontFamily: 'heading'
  },
  h6: {
    fontSize: 3,
    lineHeight: 2,
    fontWeight: 'medium',
    fontFamily: 'heading'
  },
  p: {
    fontSize: 2,
    lineHeight: 3,
    fontWeight: 'regular',
    fontFamily: 'body'
  },
  caption: {
    fontSize: 0,
    lineHeight: 1,
    fontWeight: 'bold',
    fontFamily: 'body'
  },
  button: {
    lineHeight: 0,
    fontWeight: 'regular',
    fontFamily: 'interface'
  }
};
exports.styles = styles;

var _default = function _default(_ref) {
  var colors = _ref.colors,
      theme = _objectWithoutProperties(_ref, ["colors"]);

  return (0, _utils.merge)({
    initialColorMode: 'dark',
    useCustomProperties: false,
    breakpoints: (0, _utils.values)(bps).slice(1).map(function (bp) {
      return "".concat(bp, "px");
    }),
    mediaQueries: (0, _utils.entries)(bps).reduce(function (a, _ref2) {
      var _ref3 = _slicedToArray(_ref2, 2),
          alias = _ref3[0],
          bp = _ref3[1];

      return _objectSpread({}, a, _defineProperty({}, alias, "@media (min-width: ".concat(bp, "px)")));
    }, {}),
    borderWidths: borderWidths,
    colors: (0, _makeColors["default"])({
      colors: colors
    }),
    fonts: fonts,
    fontSizes: fontSizes,
    fontWeights: fontWeights,
    lineHeights: lineHeights,
    shadows: shadows,
    space: space,
    styles: styles,
    transitions: _transitions["default"]
  }, theme);
};

exports["default"] = _default;