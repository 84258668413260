"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.useEventCallback = void 0;

var _react = require("react");

var useEnhancedEffect = typeof window !== 'undefined' ? _react.useLayoutEffect : _react.useEffect;

var useEventCallback = function useEventCallback(fn) {
  var ref = (0, _react.useRef)(fn);
  useEnhancedEffect(function () {
    ref.current = fn;
  });
  var eventHandler = (0, _react.useCallback)(function (event) {
    return (0, ref.current)(event);
  }, []);
  return typeof fn !== 'undefined' ? eventHandler : undefined;
};

exports.useEventCallback = useEventCallback;
var _default = useEventCallback;
exports["default"] = _default;