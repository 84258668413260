import { makeTheme } from '@superficial/components';
import { ThemeProvider, ColorMode, css } from '@superficial/system';
import { Global } from '@emotion/core';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import * as serviceWorker from './serviceWorker';

const gray = {
	50: '#fafafa',
	100: '#f5f5f5',
	200: '#eeeeee',
	300: '#e0e0e0',
	400: '#bdbdbd',
	500: '#9e9e9e',
	600: '#757575',
	700: '#616161',
	800: '#424242',
	900: '#212121',
	A100: '#d5d5d5',
	A200: '#aaaaaa',
	A400: '#303030',
	A700: '#616161',
};

const modes = {
	light: {
		black: '#000',
		white: '#fff',
		gray: '#777',
		text: '#000',
		background: '#fff',
		primary: '#33e',
		secondary: '#609',
	},
	dark: {
		black: '#000',
		white: '#fff',
		gray: '#999',
		text: '#fff',
		background: '#060606',
		primary: '#3cf',
		secondary: '#e0f',
	},
	deep: {
		black: '#000',
		white: '#fff',
		gray: '#6699cc',
		text: '#f0f5fa',
		background: '#222639',
		primary: '#bb99ff',
		secondary: '#ee99ff',
	},
	swiss: {
		black: '#000',
		white: '#fff',
		gray: '#996e66',
		text: '#3d2c29',
		background: '#fafaf9',
		primary: '#e63b19',
		secondary: '#2e1f7a',
	},
	muiLight: {
		black: '#000',
		white: '#fff',
		gray,
		text: '#000',
		background: '#fafafa',
		primary: '#832dd0',
		secondary: '#cf6595',
	},
	muiDark: {
		black: '#000',
		white: '#fff',
		gray,
		text: '#fff',
		background: '#212121',
		primary: '#832dd0',
		secondary: '#cf6595',
	},
};

ReactDOM.render(
	<ThemeProvider
		theme={makeTheme({ initialColorMode: 'muiDark', colors: { modes } })}
	>
		<ColorMode />
		<Global styles={theme => css({ variant: 'styles.global' })(theme)} />
		<App />
	</ThemeProvider>,
	document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
