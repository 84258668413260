"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _system = require("@superficial/system");

var _propTypes = _interopRequireDefault(require("prop-types"));

var _Button = require("./../Button/Button");

var _ButtonBase = _interopRequireDefault(require("./../ButtonBase"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(source, true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(source).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var IconButton = function IconButton(_ref) {
  var color = _ref.color,
      disabled = _ref.disabled,
      size = _ref.size,
      props = _objectWithoutProperties(_ref, ["color", "disabled", "size"]);

  return (0, _system.jsx)(_ButtonBase["default"], _extends({
    disabled: disabled
  }, props, {
    sx: _objectSpread({
      flex: '0 0 auto',
      p: '12px',
      fontSize: '24px',
      overflow: 'visible',
      borderRadius: '50%',
      textAlign: 'center',
      color: 'text.secondary',
      bg: 'transparent',
      transition: 'background-color entering entering',
      ':hover': {
        bg: 'bg.hovered',
        '@media (hover: none)': {
          bg: 'transparent'
        }
      }
    }, (0, _Button.isBrandColor)(color) && {
      color: "".concat(color, ".main"),
      ':hover': {
        bg: "".concat(color, ".onBg.hovered")
      }
    }, {}, color === 'inherit' && {
      color: 'inherit'
    }, {}, disabled && {
      bg: 'transparent',
      color: 'text.disabled'
    }, {}, size === 'small' && {
      p: '3px',
      fontSize: 18
    }, {}, size === 'large' && {
      p: '12px',
      fontSize: 24
    }, {
      /** Theme overrides */
      variant: ['buttons.icon.base', "buttons.icon.".concat(size), !disabled && "buttons.icon.".concat(color), disabled && 'buttons.icon.disabled']
    })
  }));
};

IconButton.propTypes = {
  children: _propTypes["default"].node.isRequired,
  color: _propTypes["default"].oneOf(['default', 'inherit', 'primary', 'secondary', 'tertiary']),
  disabled: _propTypes["default"].bool,
  size: _propTypes["default"].oneOf(['small', 'medium', 'large'])
};
IconButton.defaultProps = {
  as: 'button',
  disabled: false,
  size: 'medium',
  type: 'button'
};
var _default = IconButton;
exports["default"] = _default;