"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _system = require("@superficial/system");

var _propTypes = _interopRequireDefault(require("prop-types"));

var _react = require("react");

var _Box = _interopRequireDefault(require("./../Box"));

var _hooks = require("./../hooks");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(source, true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(source).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var ButtonBase = function ButtonBase(props) {
  var as = props.as,
      children = props.children,
      disabled = props.disabled,
      onBlur = props.onBlur,
      onClick = props.onClick,
      onFocus = props.onFocus,
      onKeyDown = props.onKeyDown,
      onKeyUp = props.onKeyUp,
      onMouseDown = props.onMouseDown,
      onMouseLeave = props.onMouseLeave,
      onMouseUp = props.onMouseUp,
      onTouchEnd = props.onTouchEnd,
      onTouchMove = props.onTouchMove,
      onTouchStart = props.onTouchStart,
      onDragLeave = props.onDragLeave,
      tabIndex = props.tabIndex,
      type = props.type,
      passThru = _objectWithoutProperties(props, ["as", "children", "disabled", "onBlur", "onClick", "onFocus", "onKeyDown", "onKeyUp", "onMouseDown", "onMouseLeave", "onMouseUp", "onTouchEnd", "onTouchMove", "onTouchStart", "onDragLeave", "tabIndex", "type"]);

  var keydownRef = (0, _react.useRef)(false);
  var handleDragLeave = (0, _hooks.useEventCallback)(onDragLeave);
  var handleMouseUp = (0, _hooks.useEventCallback)(onMouseUp);
  var handleMouseDown = (0, _hooks.useEventCallback)(onMouseDown);
  var handleMouseLeave = (0, _hooks.useEventCallback)(onMouseLeave);
  var handleTouchStart = (0, _hooks.useEventCallback)(onTouchStart);
  var handleTouchEnd = (0, _hooks.useEventCallback)(onTouchEnd);
  var handleTouchMove = (0, _hooks.useEventCallback)(onTouchMove);
  var handleBlur = (0, _hooks.useEventCallback)(onBlur);
  var handleFocus = (0, _hooks.useEventCallback)(function (event) {
    if (disabled) return;
    if (onFocus) onFocus(event);
  });
  var handleKeyDown = (0, _hooks.useEventCallback)(function (event) {
    if (!keydownRef.current && event.key === ' ') {
      event.persist();
    }

    if (onKeyDown) {
      onKeyDown(event);
    }
    /** Keyboard accessibility for non interactive elements. */


    if (event.target === event.currentTarget && as && as !== 'button' && (event.key === ' ' || event.key === 'Enter') && !(ref.tagName === 'A' && ref.href)) {
      event.preventDefault();

      if (onClick) {
        onClick(event);
      }
    }
  });
  var handleKeyUp = (0, _hooks.useEventCallback)(function (event) {
    keydownRef.current = false;
    event.persist();

    if (onKeyUp) {
      onKeyUp(event);
    }
  });
  var Component = as;

  if (Component === 'buton' && passThru.href) {
    Component = 'a';
  }

  var buttonProps = {};

  if (Component === 'button') {
    buttonProps.type = type;
    buttonProps.disabled = disabled;
  } else {
    if (Component !== 'a' || !passThru.href) {
      buttonProps.role = 'button';
    }

    buttonProps['aria-disabled'] = disabled;
  }

  return (0, _system.jsx)(Component, _extends({
    onBlur: handleBlur,
    onClick: onClick,
    onFocus: handleFocus,
    onKeyDown: handleKeyDown,
    onKeyUp: handleKeyUp,
    onMouseDown: handleMouseDown,
    onMouseLeave: handleMouseLeave,
    onMouseUp: handleMouseUp,
    onDragLeave: handleDragLeave,
    onTouchEnd: handleTouchEnd,
    onTouchMove: handleTouchMove,
    onTouchStart: handleTouchStart,
    tabIndex: tabIndex
  }, buttonProps, passThru, {
    sx: _objectSpread({
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',

      /** Remove gray highlight. */
      WebkitTapHighlightColor: 'transparent',

      /** Reset default value. */
      backgroundColor: 'transparent',

      /** Disable the focus ring for mouse, touch and keyboard. */
      outline: 'none',
      borderWidth: 0,

      /** Remove the margin in Safari. */
      margin: 0,
      borderRadius: 0,

      /** Remove the padding in Firefox. */
      padding: 0,
      cursor: 'pointer',
      userSelect: 'none',
      verticalAlign: 'middle',

      /** Reset */
      mozAppearance: 'none',

      /** Reset */
      webkitAppearance: 'none',
      textDecoration: 'none',

      /** To take precedent over native <a /> element style. */
      color: 'inherit',
      '::-moz-focus-inner': {
        /** Remove Firefox dotted outline. */
        borderStyle: 'none'
      }
    }, disabled && {
      /** Disable link interactions. */
      pointerEvents: 'none',
      cursor: 'default'
    })
  }), children);
};

ButtonBase.propTypes = {
  children: _propTypes["default"].node,
  className: _propTypes["default"].string,
  disabled: _propTypes["default"].bool,
  onBlur: _propTypes["default"].func,
  onClick: _propTypes["default"].func,
  onDragLeave: _propTypes["default"].func,
  onFocus: _propTypes["default"].func,
  onKeyDown: _propTypes["default"].func,
  onKeyUp: _propTypes["default"].func,
  onMouseDown: _propTypes["default"].func,
  onMouseLeave: _propTypes["default"].func,
  onMouseUp: _propTypes["default"].func,
  onTouchEnd: _propTypes["default"].func,
  onTouchMove: _propTypes["default"].func,
  onTouchStart: _propTypes["default"].func,
  role: _propTypes["default"].string,
  tabIndex: _propTypes["default"].oneOfType([_propTypes["default"].number, _propTypes["default"].string]),
  type: _propTypes["default"].oneOf(['submit', 'reset', 'button'])
};
ButtonBase.defaultProps = {
  as: 'button',
  tabIndex: 0,
  type: 'button'
};
var _default = ButtonBase;
exports["default"] = _default;