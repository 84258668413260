"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _system = require("@superficial/system");
/** @jsx jsx */


var _default = (0, _system.styled)('div')({
  boxSizing: 'border-box',
  minWidth: 0
});

exports["default"] = _default;