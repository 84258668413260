"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _utils = require("@superficial/utils");

var _colors = require("./../utils/colors");

var _makePalette = _interopRequireDefault(require("./../utils/makePalette.js"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(source, true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(source).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) {
    return;
  }

  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

var colorConfig = {
  light: {
    hues: {
      main: 500,
      dark: 700,
      light: 300
    },
    text: {
      primary: 0.87,
      secondary: 0.54,
      hint: 0.38,
      active: 0.54,
      hovered: 0.08,
      selected: 0.14,
      disabled: 0.26
    },
    border: {
      primary: 0.23,
      secondary: 0.12,
      active: 0.54,
      disabled: 0.12
    },
    bg: {
      hovered: 0.08,
      focused: 0.12,
      selected: 0.1,
      activated: 0.12,
      pressed: 0.16,
      dragged: 0.08,
      disabled: 0.12
    }
  },
  dark: {
    hues: {
      main: 300,
      dark: 500,
      light: 50
    },
    text: {
      primary: 1,
      secondary: 0.7,
      hint: 0.5,
      active: 1,
      hovered: 0.1,
      selected: 0.2,
      disabled: 0.3
    },
    border: {
      primary: 0.5,
      secondary: 0.12,
      active: 1,
      disabled: 0.3
    },
    bg: {
      hovered: 0.08,
      focused: 0.24,
      activated: 0.24,
      selected: 0.16,
      pressed: 0.24,
      dragged: 0.16,
      disabled: 0.12
    }
  }
};
var black = '#000000';
var white = '#ffffff';
var gray = {
  50: '#fafafa',
  100: '#f5f5f5',
  200: '#eeeeee',
  300: '#e0e0e0',
  400: '#bdbdbd',
  500: '#9e9e9e',
  600: '#757575',
  700: '#616161',
  800: '#424242',
  900: '#212121',
  A100: '#d5d5d5',
  A200: '#aaaaaa',
  A400: '#303030',
  A700: '#616161'
};
var pink = {
  50: '#f7ebf0',
  100: '#f7d2e1',
  200: '#f2b3cc',
  300: '#e892b6',
  400: '#db74a1',
  500: '#cf6595',
  600: '#bf5888',
  700: '#ad4e7c',
  800: '#9c466f',
  900: '#8a3e64',
  A100: '#ffb3cf',
  A200: '#ff86b2',
  A400: '#f75898',
  A700: '#d54983'
};
var purple = {
  50: '#ece1f5',
  100: '#dbbcf5',
  200: '#c390f0',
  300: '#a663e6',
  400: '#8c3ddb',
  500: '#832dd0',
  600: '#6920bd',
  700: '#5d1cad',
  800: '#53179c',
  900: '#47158a',
  A100: '#cd88ff',
  A200: '#ae45ff',
  A400: '#8503f7',
  A700: '#6e00d5'
};
var red = {
  50: '#fce6e6',
  100: '#fcbdbf',
  200: '#f78d91',
  300: '#f05960',
  400: '#e6323e',
  500: '#ef222a',
  600: '#cc1427',
  700: '#bd0f23',
  800: '#ad0c1f',
  900: '#9e0b1f',
  A100: '#ff8080',
  A200: '#ff3839',
  A400: '#ff0008',
  A700: '#e8000b'
};
var modes = {
  dark: {
    black: '#000',
    white: '#fff',
    gray: gray,
    text: '#fff',
    background: '#212121',
    primary: '#832dd0',
    secondary: '#cf6595'
  },
  deep: {
    black: '#000',
    white: '#fff',
    gray: '#6699cc',
    text: '#f0f5fa',
    background: '#222639',
    primary: '#bb99ff',
    secondary: '#ee99ff'
  },
  light: {
    black: '#000',
    white: '#fff',
    gray: gray,
    text: '#000',
    background: '#fafafa',
    primary: '#832dd0',
    secondary: '#cf6595'
  }
};

var mapValsFrom = function mapValsFrom(a, b) {
  return (0, _utils.entries)(a).map(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        key = _ref2[0],
        val = _ref2[1];

    return _defineProperty({}, key, b[val]);
  }).reduce(_utils.merge, {});
};

var mapValsWith = function mapValsWith(a, fn) {
  return (0, _utils.entries)(a).map(function (_ref4) {
    var _ref5 = _slicedToArray(_ref4, 2),
        key = _ref5[0],
        val = _ref5[1];

    return _defineProperty({}, key, fn(val));
  }).reduce(_utils.merge, {});
};

var setOpacity = function setOpacity(color) {
  return function (opacity) {
    return (0, _colors.fade)(color, opacity);
  };
};

var THEME_COLORS = ['primary', 'secondary', 'error'];

var _default = function _default(_ref7) {
  var colors = _ref7.colors;
  return {
    modes: (0, _utils.entries)(colors.modes ? colors.modes : colors).map(function (_ref8) {
      var _ref9 = _slicedToArray(_ref8, 2),
          mode = _ref9[0],
          basePalette = _ref9[1];

      var type = (0, _colors.lightOrDark)(basePalette.background || basePalette.bg);
      var config = colorConfig[type];

      var palette = _defineProperty({}, mode, {
        black: (0, _utils.get)(basePalette, 'black', black),
        white: (0, _utils.get)(basePalette, 'white', white),
        gray: (0, _utils.get)(basePalette, 'gray', gray)
      });

      if ((0, _utils.isString)(palette[mode].gray)) {
        palette[mode].gray = (0, _makePalette["default"])((0, _colors.isDark)(gray) ? (0, _colors.lighten)(gray, 10) : gray);
      }

      THEME_COLORS.forEach(function (color) {
        if (basePalette[color]) {
          var baseColor = (0, _utils.isString)(basePalette[color]) ? basePalette[color] : basePalette[color].main;
          palette[mode][color] = mapValsFrom(config.hues, (0, _makePalette["default"])(baseColor));
          palette[mode][color].contrast = {
            border: mapValsWith(config.border, setOpacity((0, _colors.isLight)(baseColor) ? '#000' : '#fff')).secondary,
            text: mapValsWith(config.text, setOpacity((0, _colors.isLight)(baseColor) ? '#000' : '#fff')).secondary
          };
          palette[mode][color].onBg = _objectSpread({}, mapValsWith(config.bg, setOpacity(baseColor)), {
            border: mapValsWith(config.border, setOpacity(baseColor)),
            text: mapValsWith(config.text, setOpacity(baseColor))
          });

          if ((0, _utils.isObject)(basePalette[color])) {
            palette[mode][color] = (0, _utils.merge)({}, palette[mode][color], basePalette[color]);
          }
        }
      });
      palette[mode].bg = _objectSpread({}, mapValsWith(config.bg, setOpacity(setOpacity(type === 'light' ? '#000' : '#fff'))), {
        main: basePalette.background || basePalette.bg,
        contrast: mapValsWith(config.text, setOpacity(type === 'light' ? '#000' : '#fff'))
      });
      palette[mode].text = mapValsWith(config.text, setOpacity(basePalette.text ? basePalette.text : type === 'light' ? black : white));
      palette[mode].border = mapValsWith(config.border, setOpacity(basePalette.border ? basePalette.border : type === 'light' ? black : white));
      return palette;
    }).reduce(_utils.merge, {})
  };
};

exports["default"] = _default;