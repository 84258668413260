/** @jsx jsx */
import { navigate, Router } from '@reach/router';
import {
	Box,
	Button,
	Container,
	H6,
	Main,
	Root,
	Sidebar,
} from '@superficial/components';
import { jsx } from '@superficial/system';
import { useRef, useState } from 'react';
import logo from './../logo.png';
import Buttons from './Buttons';
import Header from './Header';
import Home from './Home';

export default () => {
	const [ menuOpen, setMenuOpen ] = useState(false);
	const nav = useRef(null);

	return (
		<Root>
			<Header nav={nav} menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
			<Sidebar
				ref={nav}
				open={menuOpen}
				onFocus={e => setMenuOpen(true)}
				onBlur={e => setMenuOpen(false)}
				onClick={e => setMenuOpen(false)}
			>
				<div
					sx={{
						top: 0,
						position: 'sticky',
						display: 'flex',
						alignItems: 'center',
						bg: 'inherit',
						height: 64,
						py: 0,
						px: 3,
						borderBottom: 1,
						borderBottomStyle: 'solid',
						borderColor: 'border.secondary',
					}}
				>
					<Button onClick={() => navigate('/')}>
						<img
							src={logo}
							sx={{ w: 35, h: 35, mr: 3, borderRadius: 35 }}
						/>
						<H6 sx={{ m: 0 }}>Salty UI</H6>
					</Button>
				</div>
				<div sx={{ px: 3, pt: 3, pb: 4 }}>
					<Button
						sx={{ w: 1, justifyContent: 'flex-start' }}
						onClick={() => navigate('/buttons')}
					>
						Buttons
					</Button>
				</div>
			</Sidebar>
			<Main sx={{ ml: [ 0, 250 ] }}>
				<Container>
					<div
						sx={{
							display: [ 'block', 'flex' ],
						}}
					>
						<div sx={{ m: [ 0, 3 ], p: 4 }}>
							<Router>
								<Home path='/' />
								<Buttons path='buttons' />
							</Router>
						</div>
					</div>
				</Container>
			</Main>
		</Root>
	);
};
