/** @jsx jsx */
import { Icon, IconButton } from '@superficial/components';
import { jsx } from '@superficial/system';

export default props => (
	<IconButton
		{...props}
		sx={{
			color: 'contrast.primary.main',
			':hover': {
				bg: 'fade:contrast.primary.main,0.08',
			},
			'@media screen and (min-width: 40em)': {
				display: 'none',
			},
		}}
	>
		<Icon>
			<path fill='none' d='M0 0h24v24H0z' />
			<path d='M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z' />
		</Icon>
	</IconButton>
);
