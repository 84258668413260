/** @jsx jsx */
import { Card, Flex } from '@superficial/Components';
import { jsx } from '@superficial/system';

export default ({ children, ...props }) => (
	<Card {...props} sx={{ mb: 4 }}>
		<Flex
			sx={{
				justifyContent: [ 'flex-start', 'center' ],
				flexWrap: 'wrap',
			}}
		>
			{children}
		</Flex>
	</Card>
);
