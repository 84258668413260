import { inputToRGB } from '@ctrl/tinycolor';
import { Global, jsx, ThemeContext } from '@emotion/core';
import isPropValid from '@emotion/is-prop-valid';
import { get, isArray, isNumber, isObject, isString, merge, keys, assign } from '@superficial/utils';
import React, { createContext, useContext, useState, useEffect, useReducer, forwardRef } from 'react';

function _typeof(obj) {
  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function ownKeys(object, enumerableOnly) {
  var keys$$1 = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys$$1.push.apply(keys$$1, symbols);
  }

  return keys$$1;
}

function _objectSpread2(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(source, true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(source).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread();
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  }
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

function _iterableToArray(iter) {
  if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter);
}

function _iterableToArrayLimit(arr, i) {
  if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) {
    return;
  }

  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance");
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

var defaultBreakpoints = [40, 52, 64].map(function (n) {
  return n + 'em';
});
var defaultTheme = {
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72]
};
var aliases = {
  bg: 'backgroundColor',
  m: 'margin',
  mt: 'marginTop',
  mr: 'marginRight',
  mb: 'marginBottom',
  ml: 'marginLeft',
  mx: 'marginX',
  my: 'marginY',
  p: 'padding',
  pt: 'paddingTop',
  pr: 'paddingRight',
  pb: 'paddingBottom',
  pl: 'paddingLeft',
  px: 'paddingX',
  py: 'paddingY',
  w: 'width',
  h: 'height'
};
var directions = {
  marginX: ['marginLeft', 'marginRight'],
  marginY: ['marginTop', 'marginBottom'],
  paddingX: ['paddingLeft', 'paddingRight'],
  paddingY: ['paddingTop', 'paddingBottom']
};
var scales = {
  color: 'colors',
  backgroundColor: 'colors',
  borderColor: 'colors',
  margin: 'space',
  marginTop: 'space',
  marginRight: 'space',
  marginBottom: 'space',
  marginLeft: 'space',
  marginX: 'space',
  marginY: 'space',
  padding: 'space',
  paddingTop: 'space',
  paddingRight: 'space',
  paddingBottom: 'space',
  paddingLeft: 'space',
  paddingX: 'space',
  paddingY: 'space',
  top: 'space',
  right: 'space',
  bottom: 'space',
  left: 'space',
  gridGap: 'space',
  gridColumnGap: 'space',
  gridRowGap: 'space',
  fontFamily: 'fonts',
  fontSize: 'fontSizes',
  fontWeight: 'fontWeights',
  lineHeight: 'lineHeights',
  letterSpacing: 'letterSpacings',
  border: 'borders',
  borderTop: 'borders',
  borderRight: 'borders',
  borderBottom: 'borders',
  borderLeft: 'borders',
  borderWidth: 'borderWidths',
  borderStyle: 'borderStyles',
  borderRadius: 'radii',
  borderTopRightRadius: 'radii',
  borderTopLeftRadius: 'radii',
  borderBottomRightRadius: 'radii',
  borderBottomLeftRadius: 'radii',
  boxShadow: 'shadows',
  textShadow: 'shadows',
  zIndex: 'zIndices',
  width: 'sizes',
  minWidth: 'sizes',
  maxWidth: 'sizes',
  height: 'sizes',
  minHeight: 'sizes',
  maxHeight: 'sizes',
  transition: 'transitions'
};

var positiveOrNegative = function positiveOrNegative(value, scale) {
  if (typeof value !== 'number' || value >= 0) {
    return get(scale, value, value);
  }

  var absolute = Math.abs(value);
  var n = get(scale, absolute, absolute);

  if (typeof n === 'string') {
    return '-' + n;
  }

  return n * -1;
};

var fade = function fade(value, scale) {
  if (isString(value) && value.startsWith('fade:')) {
    var _value$replace$split = value.replace('fade:', '').split(','),
        _value$replace$split2 = _slicedToArray(_value$replace$split, 2),
        key = _value$replace$split2[0],
        opacity = _value$replace$split2[1];

    var style = get(scale, key, value);

    if (opacity) {
      var alpha = Number.parseFloat(opacity);

      if (!isObject(style) && alpha < 1 && alpha > 0) {
        var _inputToRGB = inputToRGB(style),
            r = _inputToRGB.r,
            g = _inputToRGB.g,
            b = _inputToRGB.b;

        return "rgba(".concat(r, ",").concat(g, ",").concat(b, ",").concat(alpha, ")");
      }
    }
  }

  return get(scale, value, value);
};

var getSize = function getSize(n, scale) {
  return get(scale, n, !isNumber(n) || n > 1 ? n : n * 100 + '%');
};

var transforms = ['margin', 'marginTop', 'marginRight', 'marginBottom', 'marginLeft', 'marginX', 'marginY', 'top', 'bottom', 'left', 'right'].reduce(function (acc, curr) {
  return _objectSpread2({}, acc, _defineProperty({}, curr, positiveOrNegative));
}, {});
transforms.color = fade;
transforms.backgroundColor = fade;
transforms.borderColor = fade;
transforms.height = getSize;
transforms.maxHeight = getSize;
transforms.minHeight = getSize;
transforms.width = getSize;
transforms.maxWidth = getSize;
transforms.minWidth = getSize;

var responsive = function responsive(styles) {
  return function (theme) {
    var next = {};
    var breakpoints = get(theme, 'breakpoints', defaultBreakpoints);
    var mediaQueries = [null].concat(_toConsumableArray(breakpoints.map(function (n) {
      return "@media screen and (min-width: ".concat(n, ")");
    })));

    for (var key in styles) {
      var value = styles[key];

      if (value == null) {
        continue;
      }

      if (!Array.isArray(value)) {
        next[key] = value;
        continue;
      }

      for (var i = 0; i < value.length; i++) {
        var media = mediaQueries[i];

        if (value[i] == null) {
          continue;
        }

        if (!media) {
          next[key] = value[i];
          continue;
        }

        next[media] = next[media] || {};
        next[media][key] = value[i];
      }
    }

    return next;
  };
};

var css = function css(args) {
  return function () {
    var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    var theme = _objectSpread2({}, defaultTheme, {}, props.theme || props);

    var obj = typeof args === 'function' ? args(theme) : args;
    var styles = responsive(obj)(theme);
    var result = {};

    for (var key in styles) {
      var prop = get(aliases, key, key);
      var scaleName = get(scales, prop);
      var scale = get(theme, scaleName, get(theme, prop, {}));
      var x = styles[key];
      var val = typeof x === 'function' ? x(theme) : x;
      /** 
       * Variants are handled by the css method. 
       * If they are functions they will be passed the theme.
       */

      if (key === 'variant') {
        var variant = isArray(val) ? val.reduce(function (a, v) {
          return merge(a, css(get(theme, v))(theme));
        }, {}) : css(get(theme, val))(theme);
        result = _objectSpread2({}, result, {}, variant);
        continue;
      }
      /** 
       * If val is an object then recursively call css(val)(theme). 
       */


      if (val && _typeof(val) === 'object') {
        result[prop] = css(val)(theme);
        continue;
      }
      /**
       * If the scale is a function pass the theme and val.
       * Otherwise get the scale value and run any transforms.
       * We want the transform signature to match styled-system
       */


      var transform = get(transforms, prop, function (value, scale) {
        return get(scale, value, value);
      });
      var value = typeof scale === 'function' ? scale(val, theme) : transform(val, scale);

      if (directions[prop]) {
        var dirs = directions[prop];

        for (var i = 0; i < dirs.length; i++) {
          result[dirs[i]] = value;
        }
      } else {
        result[prop] = value;
      }
    }

    return result;
  };
};

var Context = createContext({
  theme: {}
});

var useTheme = function useTheme() {
  return useContext(Context).theme;
};

var useSystem = function useSystem() {
  return useContext(Context);
};

var STORAGE_KEY = 'theme-color-mode';
var storage = {
  get: function get$$1(init) {
    return window.localStorage.getItem(STORAGE_KEY) || init;
  },
  set: function set(value) {
    return window.localStorage.setItem(STORAGE_KEY, value);
  }
};

var getMediaQuery = function getMediaQuery() {
  var darkQuery = '(prefers-color-scheme: dark)';
  var mql = window.matchMedia ? window.matchMedia(darkQuery) : {};
  var dark = mql.media === darkQuery;
  return dark && mql.matches;
};

var useColorState = function useColorState(initialMode) {
  var _useState = useState(initialMode),
      _useState2 = _slicedToArray(_useState, 2),
      mode = _useState2[0],
      setMode = _useState2[1];

  useEffect(function () {
    // initialize
    var stored = storage.get();
    document.body.classList.remove('theme-ui-' + stored);
    var dark = getMediaQuery();

    if (!stored && dark) {
      return setMode('dark');
    }

    if (!stored || stored === mode) {
      return;
    }

    setMode(stored);
  }, []);
  useEffect(function () {
    if (!mode) {
      return;
    }

    storage.set(mode);
  }, [mode]);
  return [mode, setMode];
};

var useColorMode = function useColorMode(initialMode) {
  var _useSystem = useSystem(),
      colorMode = _useSystem.colorMode,
      setColorMode = _useSystem.setColorMode;

  if (typeof setColorMode !== 'function') {
    throw new Error("[useColorMode] requires the ThemeProvider component");
  }

  return [colorMode, setColorMode];
};

var bodyColor = function bodyColor() {
  var theme = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  if (!theme.colors || !theme.colors.modes) {
    return;
  }

  var modes = theme.colors.modes;
  var styles = {};
  keys(modes).forEach(function (mode) {
    var colors = modes[mode];
    styles["&.theme-".concat(mode)] = theme.useCustomProperties ? colors : {
      color: colors.text,
      bg: colors.background
    };
  });
  return css({
    body: _objectSpread2({}, styles, {
      color: 'text',
      bg: 'background'
    })
  })(theme);
};

var ColorMode = function ColorMode() {
  return React.createElement(Global, {
    styles: bodyColor
  });
};

var getCSS = function getCSS(props) {
  if (!props.sx && !props.css) {
    return undefined;
  }

  return function (theme) {
    var styles = css(props.sx)(theme);
    var raw = typeof props.css === 'function' ? props.css(theme) : props.css;
    return [styles, raw];
  };
};

var parseProps = function parseProps(props) {
  if (!props) {
    return null;
  }

  var next = {};

  for (var key in props) {
    if (key === 'sx') {
      continue;
    }

    next[key] = props[key];
  }

  next.css = getCSS(props);
  return next;
};

var jsx$1 = function jsx$$1(type, props) {
  var arguments$1 = arguments;

  for (var _len = arguments.length, children = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
    children[_key - 2] = arguments$1[_key];
  }

  return jsx.apply(undefined, [type, parseProps(props)].concat(children));
};

var mergeState = function mergeState(state, next) {
  return merge({}, state, next);
};

var colorModesToCSSProperties = function colorModesToCSSProperties(modes) {
  return keys(modes).reduce(function (parsedModes, modeKey) {
    var colors = modes[modeKey];
    return _objectSpread2({}, parsedModes, _defineProperty({}, modeKey, keys(colors).reduce(function (parsedColors, colorKey) {
      return _objectSpread2({}, parsedColors, _defineProperty({}, "--theme-".concat(colorKey), colors[colorKey]));
    }, {})));
  }, {});
};

var applyCSSProperties = function applyCSSProperties(theme) {
  var colors = theme.colors;
  return _objectSpread2({}, theme, {
    colors: keys(colors).reduce(function (parsedColors, key) {
      return _objectSpread2({}, parsedColors, _defineProperty({}, key, key === 'modes' ? colorModesToCSSProperties(colors[key]) : colors[key]));
    }, {})
  });
};

var applyColorMode = function applyColorMode(theme, mode) {
  if (!mode) {
    return theme;
  }

  var modes = get(theme, 'colors.modes', {});
  return merge({}, theme, {
    colors: get(modes, mode, {})
  });
};

var BaseProvider = function BaseProvider(_ref) {
  var context = _ref.context,
      children = _ref.children;
  var theme = context.theme;
  return jsx$1(ThemeContext.Provider, {
    value: theme.useCustomProperties ? applyCSSProperties(theme) : theme
  }, jsx$1(Context.Provider, {
    value: context,
    children: children
  }));
};

var RootProvider = function RootProvider(_ref2) {
  var _ref2$theme = _ref2.theme,
      propsTheme = _ref2$theme === void 0 ? {} : _ref2$theme,
      children = _ref2.children; // components are provided in the default Context

  var outer = useSystem();

  var _useColorState = useColorState(propsTheme.initialColorMode),
      _useColorState2 = _slicedToArray(_useColorState, 2),
      colorMode = _useColorState2[0],
      setColorMode = _useColorState2[1];

  var _useReducer = useReducer(mergeState, propsTheme),
      _useReducer2 = _slicedToArray(_useReducer, 2),
      themeState = _useReducer2[0],
      setThemeState = _useReducer2[1];

  var theme = applyColorMode(themeState, colorMode);
  var context = {
    __THEME__: true,
    colorMode: colorMode,
    setColorMode: setColorMode,
    theme: theme,
    setTheme: setThemeState
  };
  useEffect(function () {
    window.__THEME__ = context;
  }, [context.theme, context.colorMode]);
  return jsx$1(BaseProvider, {
    context: context,
    children: children
  });
};

var NestedProvider = function NestedProvider(_ref3) {
  var theme = _ref3.theme,
      children = _ref3.children;
  var outer = useSystem();
  var context = merge({}, outer, {
    theme: theme
  });
  return jsx$1(BaseProvider, {
    context: context,
    children: children
  });
};

var ThemeProvider = function ThemeProvider(props) {
  var outer = useSystem();

  if (outer.__THEME__) {
    return jsx$1(NestedProvider, props);
  }

  return jsx$1(RootProvider, props);
};

var styled = function styled(tag) {
  return function () {
    var arguments$1 = arguments;

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments$1[_key];
    }

    var shouldForwardProps = typeof tag === 'function';
    var Styled = forwardRef(function (_ref, ref) {
      var as = _ref.as,
          props = _objectWithoutProperties(_ref, ["as"]);

      var theme = useContext(ThemeContext);
      var nextProps = shouldForwardProps ? props : {};
      var styles = {};
      args.forEach(function (arg) {
        var style = typeof arg === 'function' ? arg(_objectSpread2({
          theme: theme
        }, props)) : arg;
        assign(styles, style);
      });

      if (!shouldForwardProps) {
        for (var key in props) {
          if (!isPropValid(key)) {
            continue;
          }

          nextProps[key] = props[key];
        }
      }

      return jsx$1(as || tag, _objectSpread2({}, nextProps, {
        css: styles
      }));
    });
    return Styled;
  };
};

export { ColorMode, useColorMode, useSystem, useTheme, css, jsx$1 as jsx, ThemeProvider, styled };