"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.darken = darken;
exports.fade = fade;
exports.formatColor = formatColor;
exports.lighten = lighten;
exports.luminance = luminance;
exports.getContrastRatio = getContrastRatio;
exports.lightOrDark = lightOrDark;
exports.isDark = isDark;
exports.isLight = isLight;
exports.getContrast = getContrast;

var _tinycolor = _interopRequireWildcard(require("@ctrl/tinycolor"));

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};

  if (obj != null) {
    var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

    for (var key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

        if (desc && (desc.get || desc.set)) {
          Object.defineProperty(newObj, key, desc);
        } else {
          newObj[key] = obj[key];
        }
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

window.tinycolor = _tinycolor["default"];
/**
 * Darkens a color.
 *
 * @param {string} color - CSS color, i.e. one of: #nnn, #nnnnnn, rgb(), rgba(),* hsl(), hsla().
 * @param {number} coefficient - multiplier in the range 0 - 1
 * @returns {string} A CSS color string. Hex input values are returned as rgb
 */

function darken(color, coefficient) {
  return (0, _tinycolor["default"])(color).darken(coefficient).toString();
}
/**
 * Set the absolute transparency of a color.
 * Any existing alpha values are overwritten.
 *
 * @param {string} color - CSS color, i.e. one of: #nnn, #nnnnnn, rgb(), rgba(),* hsl(), hsla().
 * @param {number} value - value to set the alpha channel to in the range 0 -1
 * @returns {string} A CSS color string. Hex input values are returned as rgb
 */


function fade(src, coefficient) {
  var format = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'rgba';

  var _inputToRGB = (0, _tinycolor.inputToRGB)(src),
      r = _inputToRGB.r,
      g = _inputToRGB.g,
      b = _inputToRGB.b;

  var color = "rgba(".concat(r, ",").concat(g, ",").concat(b, ",").concat(coefficient, ")");
  return formatColor(color, format);
}
/**
 * Converts a color to a format.
 * 
 * @param  {string} src  - CSS color.
 * @param  {string} format - A CSS color format to convert the color to.
 * @return {string} A CSS color string.
 */


function formatColor(src, format) {
  if (!format) return src;
  var color = (0, _tinycolor["default"])(src);

  switch (format) {
    case 'rgb':
      return color.toRgbString();

    case 'rgba':
      return color.toRgbString();

    case 'hex':
      return color.toHexString();

    default:
      return color.toString(format);
  }
}
/**
 * Lightens a color.
 *
 * @param {string} color - CSS color, i.e. one of: #nnn, #nnnnnn, rgb(), rgba(),* hsl(), hsla().
 * @param {number} coefficient - multiplier in the range 0 - 1
 * @returns {string} A CSS color string. Hex input values are returned as rgb
 */


function lighten(color, coefficient) {
  return (0, _tinycolor["default"])(color).lighten(coefficient).toString();
}
/**
 * The relative brightness of any point in a color space,
 * normalized to 0 for darkest black and 1 for lightest white.
 * 
 * @param {string} color - CSS color, i.e. one of: #nnn, #nnnnnn, rgb(), rgba(),
 * hsl(), hsla().
 * @returns {number} The relative brightness of the color in the range 0 - 1.
 */


function luminance(color) {
  return (0, _tinycolor["default"])(color).getLuminance();
}
/**
 * Calculates the contrast ratio between two colors.
 *
 * @param {string} foreground - CSS color, i.e. one of: #nnn, #nnnnnn, rgb(), 
 * rgba(), hsl(), hsla().
 * @param {string} background - CSS color, i.e. one of: #nnn, #nnnnnn, rgb(), 
 * rgba(), hsl(), hsla().
 * @returns {number} A contrast ratio value in the range 0 - 21.
 */


function getContrastRatio(foreground, background) {
  var lumA = luminance(foreground);
  var lumB = luminance(background);
  return (Math.max(lumA, lumB) + 0.05) / (Math.min(lumA, lumB) + 0.05);
}

function lightOrDark(color) {
  return (0, _tinycolor["default"])(color).isDark() ? 'dark' : 'light';
}

function isDark(color) {
  return (0, _tinycolor["default"])(color).isDark();
}

function isLight(color) {
  return (0, _tinycolor["default"])(color).isLight();
}

function getContrast(color) {
  return isDark(color) ? '#FFF' : '#000';
}